@import "src/styles/colors.scss"; @import "src/styles/mixins.scss"; @import "src/styles/fonts.scss";
.wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  max-width: 1080px;
  width: 100%;
  margin: 0 auto;
  font-family: Avenir;

  &.isMobile {
    padding: 0 15px;
  }

  .wrapperTitle {
    display: block;
    font-style: normal;
    font-weight: 900;
    font-size: 20px;
    line-height: 27px;
    color: #2f2f2f;
    margin-top: 35px;
    padding-left: 5px;
  }

  .categoriesSlider {
    :global(.swiper-slide) > span[class*="active"] {
      font-size: 14px !important;
      font-weight: 400 !important;
    }
  }

  .subCategorySliderWrapper {
    position: relative;
    margin-top: 8px;
    margin-bottom: 12px;

    .subCategorySlider {
      overflow: hidden;
      width: 100%;

      .sliderSlide {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        width: fit-content !important;

        .sliderItem {
          display: inline-block;
          background-color: #f7f7f9;
          border: 1px solid transparent;
          font-size: 14px;
          font-weight: 400;
          line-height: 16px;
          color: #52565e;
          padding: 12px;
          border-radius: 6px;
          cursor: pointer;

          &.active {
            color: #202126;
            background-color: #ececfa;
            border-color: #a09ee7;
            cursor: default;
          }
        }
      }
    }

    .navigationWrapper {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 0 15px;
      pointer-events: none;
      z-index: 10;

      .navPrevSlide,
      .navNextSlide {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        height: 35px;
        width: 35px;
        background-color: #ffffff;
        border: 1px solid #423dcf;
        border-radius: 50%;
        font-size: 18px;
        pointer-events: all;
        color: #423dcf;
        transition: all 0.3s;
        cursor: pointer;

        &:global(.swiper-button-disabled) {
          opacity: 0;
          visibility: hidden;
        }
        @include respond(tablet) {
          display: none;
        }
      }

      .navPrevSlide {
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.1), -20px 0 5px 1px #fff;

        &:hover {
          box-shadow: 0 0 20px rgba(0, 0, 0, 0.25), -20px 0 5px 1px #fff;
        }
      }

      .navNextSlide {
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.1), 20px 0 5px 1px #fff;

        &:hover {
          box-shadow: 0 0 20px rgba(0, 0, 0, 0.25), 20px 0 5px 1px #fff;
        }
      }
    }
  }

  .storeCourseSliderWrapper {
    .navigationWrapper {
      display: flex;
      align-items: center;
      height: 100%;

      &::before,
      &::after {
        position: absolute;
        content: '';
        height: 100%;
        width: 50px;
        background: linear-gradient(to right, #ffffff, transparent);
      }

      &::after {
        right: 0;
        background: linear-gradient(to left, #ffffff, transparent);
      }

      &:has(button:first-child[class*='swiper-button-disabled'])::before,
      &:has(button:last-child[class*='swiper-button-disabled'])::after {
        display: none;
      }

      button {
        z-index: 1;
      }

      button:first-child {
        transform: translateX(32%);
      }

      button:last-child {
        transform: translateX(-50%);
      }
    }
  }
}
